<template>

  <div class="profile-image-wrapper p-1">
    <div class="profile-image p-0">
      <b-avatar
        :size="avatarSize"
        :variant="variant"
        :src="location.avatar"
        text=""
      />
    </div>
  </div>

</template>
<script>
import {
  BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },

  props: {
    location: {
      type: Object,
      default: () => {},
      required: true,
    },
    avatarSize: {
      type: String,
      default: () => '128',
      required: false,
    },
    variant: {
      type: String,
      default: () => 'light-primary',
      required: false,
    },
  },
  methods: {

  },
  setup() {

  },
}

</script>
<style scoped>

</style>
