<template>
  <div>
    <v-select
      id="pointPurposes"
      ref="pointPurposes"
      v-model="pointPurposes"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :placeholder="$t('point.which_service_do_you_require?')"
      label="item_data"
      multiple
      :filterable="false"
      :loading="fetchingPointPurposes"
      :selectable="() => filters.pointPurposes.length < 3"
      :options="optionsPointPurposes"
      @search="(search, loading) => {
        if(search.length > 2){
          loading(true)
          onSearchDebounced(search, loading)}
      }"
    >

      <template #option="{ title, point_type }">
        <div class="text-wrap">
          <strong> {{ title }}</strong>
        </div>
        <small> ({{ getTypeTitle(point_type.slug) }})</small>
      </template>
      <template #selected-option="{ title }">
        <div class="text-wrap">
          {{ title }}
        </div>
      </template>
      <template v-slot:no-options="{ search, searching,loading }">
        <span />
        <em
          v-if="search.length && !loading"
          style="opacity: 0.5"
        >{{ $t('common.no_results_found_for') }}: {{ search }}</em>
        <em
          v-else
          style="opacity: 0.5"
        > {{ $t('common.start_typing_to_search') }}</em>

      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { usePointRemoteData } from '@/views/apps/service/usePoints'
import i18n from '@/libs/i18n'
import { serviceOptions } from '@/mixins/options'
import { useDebounceFn } from '@vueuse/core'

export default {
  components: {
    vSelect,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },

  },
  data() {
    return {
      pointPurposes: [],
    }
  },
  mounted() {
    this.$watch('pointPurposes', (newValue, oldValue) => {
      if (!newValue.length) return

      const lastAddedPurpose = newValue.slice(-1)[0]

      // Delete all purposes that are of a different point type
      if (oldValue.length) {
        const previousAddedPurpose = oldValue.slice(-1)[0]

        if (previousAddedPurpose.point_type.id !== lastAddedPurpose.point_type.id) {
          this.pointPurposes = this.pointPurposes.filter(el => el.point_type.id !== previousAddedPurpose.point_type.id)
          this.fetchFilteredPurposesByPointType(lastAddedPurpose.point_type.id)
        }
      }
      this.fetchFilteredPurposesByPointType(lastAddedPurpose.point_type.id)
      this.filters.pointPurposes = this.pointPurposes.map(el => el.id)
    })
  },
  setup() {
    const optionsPointPurposes = ref([])
    const fetchingPointPurposes = ref(false)
    const { getPurposeTitle, getTypeTitle, getTranslatedPointPurposes } = serviceOptions
    const { fetchPointPurposes } = usePointRemoteData()

    const setOptionsPointPurposes = async (args, data) => {
      const optionsList = data
      optionsList.map(el => {
        el.item_data = [el.title, args.q].join(' ')
        return el.item_data
      })
      optionsPointPurposes.value = await getTranslatedPointPurposes(optionsList)
      return optionsPointPurposes.value
    }

    async function fetchFilteredPurposesByPointType(pointTypeIds) {
      fetchingPointPurposes.value = true
      const args = {
        point_type_ids: pointTypeIds,
      }
      return new Promise((resolve, reject) => {
        fetchPointPurposes(args)
          .then(async response => {
            await setOptionsPointPurposes(args, response.data.data)
            return resolve(true)
          })
          .catch(error => reject(error)).finally(() => {
            fetchingPointPurposes.value = false
          })
      })
    }

    const onSearchDebounced = useDebounceFn(async (search, loading) => {
      const args = {
        q: search,
        lang: i18n.locale,
      }

      return fetchPointPurposes(args)
        .then(async response => setOptionsPointPurposes(args, response.data.data))
        .catch(error => console.log(error)).finally(() => loading(false))
    }, 1000)

    return {
      optionsPointPurposes,
      fetchingPointPurposes,
      getPurposeTitle,
      getTypeTitle,
      getTranslatedPointPurposes,
      onSearchDebounced,
      fetchFilteredPurposesByPointType,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
<style scoped lang="scss">
.vs--disabled{
  color: #00cfe8 !important;
}

</style>
