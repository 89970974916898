<template>
  <div>
    <b-media
      v-if="data"
      no-body
      class="mb-1"
    >
      <b-media-aside class="d-none d-lg-block align-self-center">
        <b-avatar
          rounded
          variant="light-primary"
          size="20"
        >
          <feather-icon
            icon="MapPinIcon"
            size="14"
          />
        </b-avatar>
      </b-media-aside>
      <b-media-body>
        <location-map-modal
          :data="data"
        />
      </b-media-body>
    </b-media>
  </div>
</template>

<script>
import {
  BMedia, BMediaAside, BMediaBody, BAvatar,
} from 'bootstrap-vue'

import LocationMapModal from '@/views/apps/location/details/LocationMapModal.vue'

export default {
  components: {
    LocationMapModal,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
  },

  props: {
    data: {
      type: Object,
      default: () => {},
      required: true,
    },
  },

}
</script>
