<template>
  <div>
    <b-button
      variant="primary"
      tag="a"
      class="btn-cart"
      block
      @click="locationAddRequest(location)"
    >
      <feather-icon
        icon="PlusCircleIcon"
        class="mr-50"
      />
      <span class="align-middle">{{ $t('Add Request') }}</span>
    </b-button>
  </div>

</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'

import { useLocationUi } from '@/views/apps/location/useLocation'

export default {
  components: {
    BButton,
  },

  props: {
    location: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  setup() {
    const {
      locationAddRequest,
    } = useLocationUi()
    return {
      locationAddRequest,
    }
  },
}

</script>
