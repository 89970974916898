import router from '@/router'
import defaultAvatar from '@/assets/images/location/location.svg'
import store from '@/store'
import { ref } from '@vue/composition-api'

export const useLocationUi = () => {
  const getLocationRequestRoute = location => ({
    name: 'apps-service-request-add-wizard',
    query: { locationId: location.public_alias },
  })
  const getLocationRequestLink = location => {
    const props = router.resolve(getLocationRequestRoute(location))
    return props ? window.location.origin + props.href : ''
  }

  const locationEdit = location => {
    router.push({ name: 'apps-location-edit', params: { id: location.id } })
  }
  const locationAddRequest = location => {
    // window.open(getLocationRequestLink(location))
    router.push(getLocationRequestRoute(location))
  }

  const getAvatar = location => location.avatar || defaultAvatar

  const locationInfo = location => {
    router.push({ name: 'apps-location-info', params: { id: location.id } })
  }
  return {
    locationInfo,
    locationEdit,
    locationAddRequest,
    getAvatar,
    getLocationRequestLink,
  }
}
